import { mapValues, size } from 'lodash';

export const transactionCurrencyValue = (transaction) => {
  const { amount, gross_amount } = transaction;

  return gross_amount || amount;
};

// Transforms each transaction to contain simplified order lines, voided order lines and total counts
export const normaliseTransactionLineItems = (transactions) =>
  mapValues(transactions, (tr) => {
    const orderLines = tr.line_items.map((item) => ({
      ...item,
      amount: tr.totals.gross
    }));

    // Object in which we can combine similar lines
    const normalisedOrderLines = {};
    // Object to combine similar voided lines
    const voidedOrderLines = {};
    // Track the value of the order before voided lines are taken into account
    let orderValueWithoutVoids = transactionCurrencyValue(tr) || 0;
    // Count the number of voided line items
    let voidedLineItemsCount = 0;
    // Track the total number of lines items
    let totalLineItems = 0;

    if (size(orderLines)) {
      orderLines.forEach((ol) => {
        const { plu, line_item_type: type, name, qty, currency_code: currency, void: isVoided } = ol;

        const value = transactionCurrencyValue(ol) || 0;

        // Use a positive value for the cost of individual line items
        const positiveValue = Math.abs(value);

        if (plu) {
          // A line is unique by it's plu and value (price sold at) combination.
          // We combine any lines that have identical plu and value.
          const key = `plu:${plu}:value:${positiveValue}`;
          const voidedKey = `plu:${plu}:value:${positiveValue}-voided`;
          let line;

          if (!!isVoided) {
            line = voidedOrderLines[voidedKey];
          } else {
            line = normalisedOrderLines[key];
          }

          // If a line with this key has not already been found, add it
          if (!line) {
            // Simplify each line with just the info we need
            const newLine = {
              plu,
              name,
              type,
              currency,
              value: positiveValue,
              totalValue: qty * positiveValue,
              qty
            };

            if (!!isVoided) {
              // If the transaction is voided, add to voided lines and total
              voidedOrderLines[voidedKey] = newLine;
              voidedLineItemsCount += qty;
              orderValueWithoutVoids += qty * positiveValue;
            } else {
              // If the transaction is not voided, add to order lines
              normalisedOrderLines[key] = newLine;
            }
          } else {
            // Else the line has already been added, so update it's qty and total value
            const lineUpdates = {
              totalValue: line.totalValue + qty * positiveValue,
              qty: line.qty + qty
            };

            if (!!isVoided) {
              // If the transaction is voided, update voided lines and total
              voidedOrderLines[voidedKey] = {
                ...line,
                ...lineUpdates
              };
              voidedLineItemsCount += qty;
              orderValueWithoutVoids += qty * positiveValue;
            } else {
              // If the transaction is voided, update order lines and total
              normalisedOrderLines[key] = {
                ...line,
                ...lineUpdates
              };
            }
          }

          // Add to the total line items count
          totalLineItems += Math.abs(qty);
        }
      });
    }

    return {
      ...tr,
      line_items: normalisedOrderLines,
      totalLineItems,
      voidedLines: voidedOrderLines,
      voidedLineItemsCount,
      orderValueWithoutVoids
    };
  });

export const normaliseTransactionPaymentLines = (transactions) =>
  mapValues(transactions, (tr) => {
    const paymentLines = tr.payments;

    let lines = [];

    // If there are payment lines present, proceed to combine non voided cash payments
    if (paymentLines && paymentLines.length) {
      // Get any `cash` payments that aren't voided
      const nonVoidedCashPayments = paymentLines.filter((pl) => pl.payment_type === 'cash' && !!pl.void);
      // Merge them into one cash payment
      const combinedCashPayment = nonVoidedCashPayments.reduce((combinedPayment, payment) => {
        if (!combinedPayment) {
          return { ...payment };
        }
        return {
          ...combinedPayment,
          gross_value: combinedPayment.amount + payment.amount,
          gross_value_base_currency: combinedPayment.gross_value_base_currency + payment.gross_value_base_currency
        };
      }, null);

      // Filter out cash payments we have now merged
      lines = paymentLines.filter((pl) => !(pl.payment_type === 'cash' && !!pl.void));
      // Add in the merged cash payment, if there was no error
      if (combinedCashPayment) {
        lines.push(combinedCashPayment);
      }
    }

    // Amend and return the transaction object
    return {
      ...tr,
      // Update the payment lines
      payments: lines,
      // Count the number of voided payments in the transaction
      voidedPaymentsCount: lines.reduce((count, payment) => count + (!!payment.void ? 1 : 0), 0),
      // Add up the total payments value including void payments
      paymentsTotalWithVoids: lines.reduce((total, payment) => total + (transactionCurrencyValue(payment) || 0), 0),
      currency_code: tr.totals.currency_code,
      amount: tr.totals.gross
    };
  });

export const voidedText = ({ t, voided }) => {
  let text = '';

  if (voided === 'order') {
    text = t('ORDER_VOIDED');
  } else if (voided === 'item') {
    text = t('VOIDED');
  } else if (voided) {
    text = `${voided} ${voided === 1 ? t('ITEM_VOIDED') : t('ITEMS_VOIDED')}`;
  }

  return text;
};
